@import '../../styles/const';

.text-field {
  position: relative;
  width: 100%;

  border: 1px solid #191919;
  border-radius: 10px;

  &__label {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);

    display: flex;
    align-items: center;

    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    color: #a1aeb7;
    transition: 0.1s ease-out;
    margin: 0;

    &.focused {
      top: -8.5px;
      left: 14px;
      transform: initial;

      background: #ffffff;
      padding: 0 4px;

      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      text-transform: capitalize;
      color: #191919;
    }

    @media screen and (max-width: $md) {
      left: 10px;
      &.focused {
        top: -5.5px;
      }
    }
  }

  &__input {
    width: 100%;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #191919;

    padding: 16px;

    @media screen and (max-width: $md) {
      padding: 16px 10px;
    }
  }
}

@import '../../styles/const';

.spaces {
  &.wrapper {
    display: flex;
    flex-direction: column;
    grid-row-gap: 24px;
    padding: 24px 40px;

    @media screen and (max-width: $md) {
      padding: 24px 15px;
    }
  }

  &.loading > &__loader {
    display: flex;
    height: 200px;
    align-items: center;
    justify-content: center;
  }

  &.loading > &__select {
    display: none;
  }

  &__select {
    display: flex;
    flex-direction: column;
    max-height: 379px;
    overflow: auto;

    &.wrapper {
      border: 1px solid #e4ecef;
      border-radius: 16px;
    }

    &__space {
      padding: 8px 24px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      border-bottom: 1px solid #e4ecef;

      &.clickable {
        cursor: pointer;
      }

      &:last-child {
        border-bottom: none;
      }

      @media screen and (max-width: $md) {
        padding: 8px 10px;
      }

      &__name {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;

        display: flex;
        align-items: center;
        flex-wrap: wrap;

        grid-column-gap: 5px;
        color: #191919;
      }

      &__btn {
        padding: 10px 16px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #3c7bff;
        height: 38px;

        &[disabled] {
          color: #c4c4c4;
        }
      }
    }
  }
}

#root {
  display: flex;
}

.win-title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: #191919;

  margin: 0;

  &.clickable {
    cursor: pointer;
  }

  &__icon {
    cursor: pointer;
    margin-right: 24px;
  }
}

.win-description {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #333333;

  margin: 0;
}

.action-button {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 16px;
  background: #3c7bff;
  box-shadow: 0px 2px 10px rgba(46, 82, 101, 0.1);
  border-radius: 8px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;

  &.disabled {
    opacity: 0.5;
  }
}

@import '../../../styles/const';

.search-field {
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  padding: 8px 18px;
  background: #e4ecef;
  border: 1.33333px solid #e4ecef;
  border-radius: 8px;

  @media screen and (max-width: $md) {
    padding: 8px 10px;
  }

  &__input {
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 33px;
    color: #191919;

    &::placeholder {
      color: #a1aeb7;
    }
  }
}

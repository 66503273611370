@import '../../styles/const';

.outer-wrapper {
  min-height: 100vh;
  background: #eff1f4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 0 15px;

  @media screen and (max-width: $md) {
    background: #ffffff;
  }

  &__inner-wrapper {
    display: flex;
    max-width: 80%;
    background: #ffffff;
    border-radius: 20px;

    @media screen and (max-width: $lg) {
      max-width: 100%;
    }
  }
}

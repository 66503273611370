@import '../../styles/const';

.password-main {
  &.wrapper {
    display: flex;
    flex-direction: column;
    grid-row-gap: 24px;
    padding: 24px;

    @media screen and (max-width: $md) {
      padding: 24px 15px;
    }
  }

  .password__error {
    color: #ff4b4b;
  }
}
